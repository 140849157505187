import * as React from "react";
import StyledButton from "../../ui/StyledButton";

export interface IRemoveLeaksSectionProps {
  onRemoveLeaksClicked?: () => void;
}

export function RemoveLeaksSection(props: IRemoveLeaksSectionProps) {
  return (
    <div>
      <div className="px-4 flex flex-col gap-4 items-center text-md text-gray-700 dark:text-white">
        <p className="font-semibold">
          Want somebody to take care of your leaks?
        </p>

        <a href="https://www.trustpilot.com/review/remove.tech">
          <img
            src={"./trust-pilot.png"}
            width={180}
            alt="trust pilot 4.5 stars"
          />
        </a>

        <p>
          We remove leaked content for <span>200+</span> happy creators
          worldwide
        </p>
      </div>

      <div className="mt-5 flex justify-center">
        <StyledButton
          design="primary"
          type="button"
          onClick={() => {
            props.onRemoveLeaksClicked && props.onRemoveLeaksClicked();
          }}
        >
          Remove the leaks
        </StyledButton>
      </div>
    </div>
  );
}
