import { Controller, useForm } from "react-hook-form";
import StyledInput from "../../../forms/StyledInput";
import StyledButton from "../../../ui/StyledButton";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export interface ITextInputFormProps {
  text?: string;
  placeholder?: string;
  prefix?: string;
  suffix?: string;
  onSubmit: (value: string) => void;
  validator?: yup.ObjectSchema<ITextInputFormData>;
}

export interface ITextInputFormData {
  text: string;
}

export function TextInputForm(props: ITextInputFormProps) {
  const { control, handleSubmit } = useForm<ITextInputFormData>({
    defaultValues: { text: props.text ?? "" },
    mode: "onSubmit",
    resolver: props.validator ? yupResolver(props.validator) : undefined,
  });

  return (
    <form onSubmit={handleSubmit((data) => props.onSubmit(data.text))}>
      <div className="flex justify-center gap-2">
        <div className="w-full max-w-xs">
          <Controller
            name="text"
            control={control}
            render={(s) =>
              StyledInput({
                ...s,
                prefix: props.prefix,
                suffix: props.suffix,
                inputType: "text",
                placeholder: props.placeholder,
              })
            }
          />
        </div>

        <StyledButton
          type="submit"
          design="primary"
          classNames="max-h-8 px-4 py-1 sm:mr-[-58px] mt-[3px]"
          disablePadding={true}
        >
          <ChevronRightIcon className="size-6" />
        </StyledButton>
      </div>
    </form>
  );
}
