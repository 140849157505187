import Container from "typedi";

import {
  INotificationService,
  NotificationService,
} from "../notifications/NotificationService";
import { ServiceTokens } from "./ServiceTokens";
import { ILeakCheckApiCaller } from "../api/ILeakCheckApiCaller";
import { LeakCheckApiCaller } from "../api/impl/LeakCheckApiCaller";

export function registerServices(): void {
  Container.set<string>(
    ServiceTokens.ApiBaseUrl,
    process.env.REACT_APP_API_BASE_URL
  );

  Container.set<INotificationService>({
    id: ServiceTokens.NotificationServiceToken,
    type: NotificationService,
    global: true,
    transient: false,
  });

  Container.set<ILeakCheckApiCaller>({
    id: ServiceTokens.LeakCheckApiCallerServiceToken,
    type: LeakCheckApiCaller,
    global: false,
    transient: true,
  });
}
