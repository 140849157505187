import { IInteractionItemProps } from "../../interfaces/IInteractionItemProps";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import InteractionContentContainer from "../interaction-content-container/InteractionContentContainer";
import { RemoveLeaksSection } from "./remove-leaks-section";

export interface IDeepSearchResultProps
  extends IInteractionItemProps<{ id: number }> {
  section: string;
  onRemoveLeaksClicked: () => void;
}

export function DeepSearchResult(props: IDeepSearchResultProps) {
  return (
    <InteractionContentContainer
      section={props.section}
      hasBackButton={props.hasBackButton}
      onBackButtonClicked={props.onBackButtonClicked}
      id={props.id}
    >
      <div className="pb-5 px-4 text-center text-lg text-gray-700 dark:text-white font-semibold">
        Thanks you for your contact information!
      </div>

      <div className="flex justify-center text-black dark:text-white">
        <CheckCircleIcon className="size-10" />
      </div>

      <div className="pt-5 px-4 text-center text-md text-gray-700 dark:text-white">
        Please give us some time, we will contact you with the results when we
        have resources available.
      </div>

      <div className="py-7">
        <hr />
      </div>

      <RemoveLeaksSection
        onRemoveLeaksClicked={() => props.onRemoveLeaksClicked()}
      />
    </InteractionContentContainer>
  );
}
