import Container from "typedi";
import { ILeakCheckApiCaller } from "../ILeakCheckApiCaller";
import { ServiceTokens } from "../../util/ServiceTokens";
import { IAnonLeakCheck } from "../../models/IAnonLeakCheck";
import { IContactInfo } from "../../models/IContactInfo";
import { axiosMod } from "../../util/ModifiedAxios";

export class LeakCheckApiCaller implements ILeakCheckApiCaller {
  private readonly baseUrl: string;

  constructor() {
    this.baseUrl = Container.get<string>(ServiceTokens.ApiBaseUrl);
  }

  async addAsAnon(item: IAnonLeakCheck): Promise<IAnonLeakCheck> {
    const response = await axiosMod().post<IAnonLeakCheck>(
      this.baseUrl + "/anon/leak-checks",
      item
    );

    return response.data;
  }

  async addContactInfo(id: string, info: IContactInfo): Promise<IContactInfo> {
    const response = await axiosMod().post<IContactInfo>(
      this.baseUrl + `/anon/leak-checks/${id}/contact-info`,
      info
    );

    return response.data;
  }
}
