import { Token } from "typedi";

export class ServiceTokens {
  public static readonly ApiBaseUrl = new Token("ApiBaseUrl");

  public static readonly NotificationServiceToken = new Token(
    "NotificationServiceToken"
  );

  public static readonly LeakCheckApiCallerServiceToken = new Token(
    "LeakCheckApiCallerServiceToken"
  );
}
