import * as React from "react";
import { IInteractionItemProps } from "../../interfaces/IInteractionItemProps";
import InteractionContentContainer from "../interaction-content-container/InteractionContentContainer";
import StyledButton from "../../ui/StyledButton";
import {
  ArchiveBoxXMarkIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { RemoveLeaksSection } from "./remove-leaks-section";

export interface ICreatorInstantResultProps
  extends IInteractionItemProps<{
    id: number;
    action: CreatorInstantResultAction;
  }> {
  section: string;
  links: string[];
}

export enum CreatorInstantResultAction {
  DeepSearch,
  RemoveLeaks,
}

export default class CreatorInstantResult extends React.Component<ICreatorInstantResultProps> {
  private linksPresentResult() {
    return (
      <div>
        <div className="pb-5 px-4 text-center text-lg text-gray-700 dark:text-white font-semibold">
          We have found multiple leaks that might belong to you
        </div>

        <ul className="text-center text-primary-700 dark:text-indigo-500">
          {this.props.links.map((x) => (
            <li key={x}>
              <a target="_blank" rel="noreferrer" href={x}>
                {x}
              </a>
            </li>
          ))}
        </ul>

        <div className="pt-5 px-4 text-center text-md text-gray-700 dark:text-white">
          The links shown above represent an excerpt of the elements we found.
          Please use them to validate if the leaks are indeed from your profile.
        </div>

        <div className="mt-5 flex justify-center gap-3">
          <StyledButton
            design="primary"
            type="button"
            onClick={() =>
              this.props.completed &&
              this.props.completed({
                id: this.props.id,
                action: CreatorInstantResultAction.DeepSearch,
              })
            }
          >
            Show me more results
          </StyledButton>
        </div>

        <div className="py-7">
          <hr />
        </div>

        <RemoveLeaksSection
          onRemoveLeaksClicked={() =>
            this.props.completed &&
            this.props.completed({
              id: this.props.id,
              action: CreatorInstantResultAction.RemoveLeaks,
            })
          }
        />
      </div>
    );
  }

  private noLinksPresentResult() {
    return (
      <div>
        <div className="pb-5 px-4 text-center text-lg text-gray-700 dark:text-white font-semibold">
          We did not find any instant results with the provided information
        </div>
        <div className="flex justify-center text-black dark:text-white">
          <ArchiveBoxXMarkIcon className="size-10" />
        </div>
        <div className="pt-5 px-4 text-center text-md text-gray-700 dark:text-white">
          If you would like one of our agents performs a deep search and sends
          you the results.
        </div>
        <div className="mt-5 flex justify-center gap-3">
          <StyledButton
            design="primary"
            type="button"
            onClick={() =>
              this.props.completed &&
              this.props.completed({
                id: this.props.id,
                action: CreatorInstantResultAction.DeepSearch,
              })
            }
          >
            <div className="flex items-center gap-2">
              <MagnifyingGlassIcon className="size-5" />
              <p>
                Request a <span className="font-bold">free</span> deep search
              </p>
            </div>
          </StyledButton>
        </div>
      </div>
    );
  }

  public render() {
    return (
      <InteractionContentContainer
        section={this.props.section}
        hasBackButton={this.props.hasBackButton}
        onBackButtonClicked={this.props.onBackButtonClicked}
        id={this.props.id}
      >
        {this.props.links.length > 0
          ? this.linksPresentResult()
          : this.noLinksPresentResult()}
      </InteractionContentContainer>
    );
  }
}
