import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import { v4 as uuidv4 } from "uuid";

export interface StyledButtonProps {
  onClick?: () => void;

  design?: "default" | "primary" | "error";
  type: "submit" | "reset" | "button";
  disabled?: boolean;
  classNames?: string;
  tooltip?: string;
  disablePadding?: boolean;

  children?: React.ReactNode;
}

export function StyledButton(props: StyledButtonProps) {
  const { type, onClick, children, disabled } = props;

  const isPrimary = props.design && props.design === "primary";
  const isError = props.design && props.design === "error";

  const isDefault = !isPrimary && !isError;

  const tooltipId = uuidv4();

  return (
    <div className="inline-flex">
      {props.tooltip && <Tooltip id={tooltipId}></Tooltip>}

      <button
        type={type}
        onClick={() => onClick && onClick()}
        disabled={disabled}
        data-tooltip-id={tooltipId}
        data-tooltip-content={props.tooltip}
        className={classNames(
          props.disablePadding === true ? "" : "px-4 py-2",
          "flex rounded-md border text-sm font-medium  shadow-sm select-none", //frame + alignments
          "disabled:opacity-50", //effects
          !isDefault && "border-transparent", //default on desgined buttons
          !isDefault && "focus:outline-none focus:ring-2 focus:ring-offset-2", //effects on designed buttons
          /** STYLE */
          isPrimary &&
            "bg-primary-700 dark:bg-indigo-500 text-white disabled:bg-gray-400",
          isPrimary &&
            !disabled &&
            "hover:bg-primary-900 dark:hover:bg-indigo-400 focus:ring-primary-700 dark:focus:ring-indigo-300",
          isError && "bg-red-800 text-white",
          isError && !disabled && "hover:bg-red-900 focus:ring-red-700",
          isDefault && "bg-white text-black border-gray-300",
          isDefault && !disabled && "hover:bg-gray-50",
          props.classNames && props.classNames
        )}
      >
        {children}
      </button>
    </div>
  );
}

export default StyledButton;
