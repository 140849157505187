import * as React from "react";
import { IInteractionItemProps } from "../../interfaces/IInteractionItemProps";
import { IStringAnswer } from "../../interfaces/IStringSelection";
import InteractionContentContainer from "../interaction-content-container/InteractionContentContainer";
import { Turnstile } from "@marsidev/react-turnstile";

export interface ICaptchaAnswerProps
  extends IInteractionItemProps<IStringAnswer> {
  text: string;
  section: string;
}

export function CaptchaAnswer(props: ICaptchaAnswerProps) {
  const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY ?? "";

  return (
    <InteractionContentContainer
      section={props.section}
      hasBackButton={props.hasBackButton}
      onBackButtonClicked={props.onBackButtonClicked}
      id={props.id}
      isLoading={props.isLoading}
    >
      <div>
        <div className="pb-5 px-4 text-center text-md text-gray-700 dark:text-white font-semibold">
          {props.text}
        </div>

        <div className="flex justify-center">
          <Turnstile
            siteKey={captchaSiteKey}
            onError={() => console.warn("error")}
            onExpire={() => console.warn("expired")}
            onSuccess={(x) =>
              x && props.completed && props.completed({ id: 1, value: x })
            }
          />
        </div>
      </div>
    </InteractionContentContainer>
  );
}
