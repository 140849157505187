import React from "react";
import { IInteractionItemProps } from "../../interfaces/IInteractionItemProps";
import { IStringAnswer } from "../../interfaces/IStringSelection";
import InteractionContentContainer from "../interaction-content-container/InteractionContentContainer";
import StyledButton from "../../ui/StyledButton";

interface IOptionAnswerProps extends IInteractionItemProps<IStringAnswer> {
  section: string;
  question: string;
  link?: string;
  options: string[];
}

export default class OptionAnswer extends React.Component<IOptionAnswerProps> {
  private generateOptions(): JSX.Element[] {
    return this.props.options.map((x) => {
      return (
        <div key={x}>
          <StyledButton
            type="button"
            design="primary"
            classNames="min-w-40"
            onClick={() =>
              this.props.completed &&
              this.props.completed({
                id: this.props.id,
                value: x,
              })
            }
          >
            <div className="text-center w-full">{x}</div>
          </StyledButton>
        </div>
      );
    });
  }

  public render() {
    return (
      <InteractionContentContainer
        section={this.props.section}
        hasBackButton={this.props.hasBackButton}
        onBackButtonClicked={this.props.onBackButtonClicked}
        id={this.props.id}
        isLoading={this.props.isLoading}
      >
        <div>
          <div className="pb-5 px-4 text-center text-md text-gray-700 dark:text-white font-semibold">
            {this.props.question}
          </div>

          {this.props.link && (
            <div className="pb-5">
              <hr />
              <ul className="text-center text-primary-700 dark:text-indigo-500 py-5">
                <li>
                  <a target="_blank" rel="noreferrer" href={this.props.link}>
                    {this.props.link}
                  </a>
                </li>
              </ul>
              <hr />
            </div>
          )}

          <div className="flex justify-center">
            <div className="flex flex-col gap-2">{this.generateOptions()}</div>
          </div>
        </div>
      </InteractionContentContainer>
    );
  }
}
