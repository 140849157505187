import React from "react";
import { IInteractionItemProps } from "../../../interfaces/IInteractionItemProps";
import { IStringAnswer } from "../../../interfaces/IStringSelection";
import InteractionContentContainer from "../../interaction-content-container/InteractionContentContainer";
import { ITextInputFormData, TextInputForm } from "./TextInputForm";
import * as yup from "yup";

interface ITextInputAnswerProps extends IInteractionItemProps<IStringAnswer> {
  section: string;
  question: string;
  placeholder?: string;
  value?: string;
  prefix?: string;
  suffix?: string;

  validator?: yup.ObjectSchema<ITextInputFormData>;
}

export default class TextInputAnswer extends React.Component<ITextInputAnswerProps> {
  public render() {
    return (
      <InteractionContentContainer
        section={this.props.section}
        hasBackButton={this.props.hasBackButton}
        onBackButtonClicked={this.props.onBackButtonClicked}
        id={this.props.id}
        isLoading={this.props.isLoading}
      >
        <div>
          <div className="pb-5 px-4 text-center text-md text-gray-700 dark:text-white font-semibold">
            {this.props.question}
          </div>

          <TextInputForm
            prefix={this.props.prefix}
            suffix={this.props.suffix}
            onSubmit={(x) =>
              this.props.completed &&
              this.props.completed({ id: this.props.id, value: x })
            }
            placeholder={this.props.placeholder}
            text={this.props.value}
            validator={this.props.validator}
          />
        </div>
      </InteractionContentContainer>
    );
  }
}
