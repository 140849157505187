import { BehaviorSubject, delay, Observable, of, tap } from "rxjs";

export interface INotificationDto {
  type: "success" | "normal" | "error" | "failure";
  title: string;
  description?: string;
  hideAfterSeconds?: number;
}

export interface IExtendedNotificationDto extends INotificationDto {
  show: boolean;
}

export interface INotificationService {
  get notifications$(): Observable<INotificationDto[]>;

  createError(
    title: string,
    description?: string,
    hideAfterSeconds?: number
  ): void;
  createSuccess(
    title: string,
    description?: string,
    hideAfterSeconds?: number
  ): void;

  createNotification(data: INotificationDto): void;
  deleteNotification(data: INotificationDto): void;
}

export class NotificationService implements INotificationService {
  private _notifications = new BehaviorSubject<INotificationDto[]>([]);
  private _notifications$ = this._notifications.asObservable();

  createError(
    title: string,
    description?: string | undefined,
    hideAfterSeconds?: number | undefined
  ) {
    this.createNotification({
      type: "error",
      title,
      description,
      hideAfterSeconds,
    });
  }

  createSuccess(
    title: string,
    description?: string | undefined,
    hideAfterSeconds?: number | undefined
  ) {
    this.createNotification({
      type: "success",
      title,
      description,
      hideAfterSeconds,
    });
  }

  createNotification(data: INotificationDto): void {
    const newNotification: INotificationDto = {
      ...data,
    };

    this._notifications.next([...this._notifications.value, newNotification]);

    if (newNotification.hideAfterSeconds) {
      of(newNotification)
        .pipe(
          delay(newNotification.hideAfterSeconds * 1000.0),
          tap((item) => this.deleteNotification(item))
        )
        .subscribe();
    }
  }

  get notifications$(): Observable<INotificationDto[]> {
    return this._notifications$;
  }

  public deleteNotification(data: INotificationDto): void {
    let current = this._notifications.value;
    current = current.filter((e) => e !== data);
    this._notifications.next(current);
  }
}
