import * as React from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import PlaceholderLoadingSpinner from "../../ui/PlaceholderLoadingSpinner";

interface IInteractionContentContainerProps {
  id: number;
  section: string;
  hasBackButton?: boolean;
  children: React.ReactNode;
  isLoading?: boolean;

  onBackButtonClicked?: () => void;
}

export default class InteractionContentContainer extends React.Component<IInteractionContentContainerProps> {
  static collapseContainer = (id: number) => {
    const accordion = document.getElementById("inter-cc-col-" + id);

    if (accordion?.classList.contains("show")) {
      document.getElementById("inter-cc-col-toggle-" + id)?.click();
    }
  };

  static openContainer = (id: number) => {
    const accordion = document.getElementById("inter-cc-col-" + id);
    const element = document.getElementById("inter-cc-col-toggle-" + id);

    if (accordion && !accordion?.classList.contains("show")) {
      element?.click();
    }

    element?.closest(".accordion")?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  public render() {
    return (
      <div
        className="mx-auto max-w-4xl py-6 sm:px-6 lg:px-8"
        key={this.props.id}
      >
        <div className="mx-auto max-w-none">
          <div className="overflow-hidden bg-white dark:bg-slate-800 sm:rounded-lg sm:shadow px-4 py-5 sm:px-6">
            <div className=" border-gray-200 dark:border- slate-900/5  flex justify-between items-center">
              {this.props.hasBackButton === true ? (
                <button
                  disabled={this.props.isLoading}
                  onClick={() =>
                    this.props.onBackButtonClicked &&
                    this.props.onBackButtonClicked()
                  }
                  type="button"
                  className="text-primary-600 dark:text-indigo-400 hover:text-primary-900 dark:hover:text-indigo-300 inline-flex items-center gap-2 font-semibold"
                >
                  <ChevronLeftIcon className="size-6" />
                  <div>Go back</div>
                </button>
              ) : (
                <div></div>
              )}

              <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                {this.props.section}
              </span>
            </div>

            {this.props.isLoading === true ? (
              <div className="py-6 flex justify-center">
                <PlaceholderLoadingSpinner />
              </div>
            ) : (
              <div className="py-6">{this.props.children}</div>
            )}
          </div>
        </div>
      </div>
    );

    // return (
    //   <Accordion defaultActiveKey={this.props.id.toString()}>
    //     <Card
    //       className={
    //         this.props.isOverflowVisible ? "my-2 overflow-visible" : "my-2"
    //       }
    //     >
    //       <Card.Body>
    //         <CustomToggle
    //           id={"inter-cc-col-toggle-" + this.props.id}
    //           eventKey={this.props.id.toString()}
    //         >
    //           <div className="d-flex px-1 justify-content-between">
    //             <div>{this.props.heading}</div>
    //             <div>{this.getBadge()}</div>
    //           </div>
    //         </CustomToggle>

    //         <Accordion.Collapse
    //           id={"inter-cc-col-" + this.props.id}
    //           eventKey={this.props.id.toString()}
    //           className="mt-4"
    //         >
    //           <div>{this.props.children}</div>
    //         </Accordion.Collapse>
    //       </Card.Body>
    //     </Card>
    //   </Accordion>
    // );
  }
}
