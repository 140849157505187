/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { Axios } from "axios";
import { parseISO } from "date-fns";
import { CalendarDate } from "calendar-date";

export function axiosMod(): Axios {
  const client = axios.create();

  client.interceptors.response.use((originalResponse) => {
    handleTypesResponse(originalResponse.data);
    return originalResponse;
  });

  return client;
}

const isoDateTimeFormat =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isoDateFormat = /^\d{4}-\d{2}-\d{2}$/;

function isIsoDateTimeString(value: any): boolean {
  return value && typeof value === "string" && isoDateTimeFormat.test(value);
}

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

function handleTypesResponse(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateTimeString(value)) {
      body[key] = parseISO(value);
    } else if (isIsoDateString(value)) {
      body[key] = new CalendarDate(value);
    } else if (typeof value === "object") {
      handleTypesResponse(value);
    }
  }
}
