import classNames from "classnames";
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from "react-hook-form";
import React, { HTMLInputTypeAttribute } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { format } from "date-fns";

export function StyledInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(args: {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TFieldValues>;

  //optional
  title?: string | React.ReactNode;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  numberStepSize?: string;
  textAlign?: "left" | "right" | "center";
  inputType?: HTMLInputTypeAttribute;
  placeholder?: string;
  disabled?: boolean;
}) {
  const { field, fieldState } = args;

  const showError = fieldState.invalid;

  const isCheckbox = args?.inputType === "checkbox";

  const getDefaultValue = () => {
    const defaultValue = field.value as unknown;

    if (args?.inputType === "datetime-local" && defaultValue instanceof Date) {
      return format(defaultValue, "yyyy-MM-dd'T'HH:mm");
    } else if (args?.inputType === "date" && defaultValue instanceof Date) {
      return format(defaultValue, "yyyy-MM-dd");
    } else if (args?.inputType === "checkbox") {
      return undefined;
    }

    return field.value;
  };

  const getDefaultChecked = () => {
    if (args?.inputType === "checkbox") {
      return field.value;
    }
    return undefined;
  };

  return (
    <div>
      {args?.title && !isCheckbox && (
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor={field.name}
        >
          {args?.title}
        </label>
      )}

      <div
        className={classNames(
          "flex rounded-md relative",
          !isCheckbox && "shadow-sm"
        )}
      >
        {args?.prefix !== undefined && (
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
            {args?.prefix}
          </span>
        )}

        <div className={"relative block w-full"}>
          <input
            id={field.name}
            placeholder={args.placeholder}
            className={classNames(
              showError &&
                "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500",
              !showError && "border-black",
              "min-w-0 flex-1 border-gray-300 focus:border-primary-700 dark:focus:border-indigo-300 focus:ring-primary-700 dark:focus:ring-indigo-300 text-sm text-black",
              args?.suffix === undefined && "rounded-r-md",
              args?.prefix === undefined && "rounded-l-md",
              !isCheckbox && "w-full",
              isCheckbox && "align-middle",
              args?.textAlign === "right" && "text-right",
              args?.textAlign === "center" && "text-center",
              args?.textAlign === "left" && "text-left"
            )}
            type={args?.inputType ?? "text"}
            defaultValue={getDefaultValue()}
            defaultChecked={getDefaultChecked()}
            name={field.name}
            step={args?.numberStepSize}
            disabled={args?.disabled}
            onChange={(event) => field.onChange(event)}
          />

          {args?.title && isCheckbox && (
            <label className="ml-2 text-sm font-medium text-gray-700 align-middle">
              {args?.title}
            </label>
          )}

          {showError && (
            <div
              className={classNames(
                "pointer-events-none absolute inset-y-0 flex items-center ",
                args?.textAlign === "right" && "left-0 pl-3",
                args?.textAlign !== "right" && "right-0 pr-3"
              )}
            >
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>

        {args?.suffix !== undefined && (
          <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
            {args?.suffix}
          </span>
        )}
      </div>

      {showError && (
        <p className="mt-2 text-sm text-red-600">{fieldState.error?.message}</p>
      )}
    </div>
  );
}

export default StyledInput;
